<template>
  <div class="auther-dynamic-containers">
    <div class="container">
      <div v-for="(item, i) in allItemList" :key="i">
        <FollowUpdate v-if="item.type === 1" :every="item" :photo="photo" :name="name"></FollowUpdate>
        <FollowUpdate1 v-if="item.type === 2" :every="item" :photo="photo" :name="name"></FollowUpdate1>
      </div>
      <div class="no-dynamic" v-show="noShow && !isLoding">
        <img :src="require('@/assets/qietu/dynamic.png')" alt="">
        <p>暂无动态</p>
      </div>
      <p v-show="isLoding" style="text-align: center;color: rgb(105, 98, 98);">加载中....</p>
    </div>
  </div>
</template>

<script>
import FollowUpdate from "../components/FollowUpdate.vue";
import FollowUpdate1 from "../components/FollowUpdate1.vue";
export default {
  components: {
    FollowUpdate,
    FollowUpdate1,
  },
  data() {
    return {
      isLoding: false, // 加载loding
      pageNum: 1, // 当前页数
      total: 0, // 总条数
      sideList: [],
      allItemList: [],
      photo: "",
      name: ""
    };
  },
  computed: {
    noShow() {
      return this.allItemList.length === 0
    }
  },
  created() {
    this.spaceScholarDynamic({
      pageNo: 1,
      pageSize: 10,
      userId: this.$route.query.id,
    });
  },
  methods: {
    // 监听滚动条
    handleScroll() {
      const homeDoc = document.getElementsByClassName('auther-content')
      if ((document.documentElement.scrollTop+document.body.offsetHeight) == homeDoc[0].offsetHeight ) {
        if (this.total / 10 > this.pageNum && !this.isLoding) {
          this.spaceScholarDynamic({
            pageNo: this.pageNum + 1,
            pageSize: 10,
            userId: this.$route.query.id,
          });
        }
      }
    },
    async spaceScholarDynamic(data) {
      this.isLoding = true
      const resp = await this.$apis.autherServe.spaceScholarDynamic(data);
      if (resp.data) {
        this.allItemList.push(...resp.data.dynamicPageInfo.list);
        this.pageNum = +resp.data.dynamicPageInfo.pageNum  
        this.total = +resp.data.dynamicPageInfo.total
        this.photo = resp.data.headPhoto
        this.name = resp.data.realname
      }
      this.isLoding = false
      window.addEventListener('scroll',this.handleScroll) // 给滚动条添加事件
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-dynamic-containers {
  width: 1040px;
  .container {
    width: 100%;
    margin-right: 40px;
    .no-dynamic {
      padding-top: 50px;
      text-align: center;
      font-size: 16px;
      color: #999999;
      img {
        width: 100%;
        // height: 304px;
      }
    }
  }
}
</style>
