<template>
  <div class="update-container">
    <div class="headr">
      <div class="left">
        <img v-lazy="$global.imgUrl + photo" alt="" />
        <div class="info">
          <div class="name">
            {{ name }}
          </div>
          <div class="update">回复了一个讨论</div>
        </div>
      </div>
      <div class="right">
        <span @click="goDetail">{{ every.circleName }}</span> 
        <span  style="color:#333;">- 更新了回复</span>
      </div>
    </div>
    <div class="title" @click="goDetail">{{ every.title }}</div>
    <div class="contents">
      {{ every.content }}
    </div>
    <div class="createDate">{{ every.createDate | dayFormate }}</div>
  </div>
</template>

<script>
export default {
  props: {
    every: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    photo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      autherObj: {},
    };
  },
  created() {
    // this.spaceScholarInfo({
    //   userId: this.$route.query.id,
    // });
  },
  methods: {
    goDetail() {
      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) return
      let url = this.$router.resolve({
        path: "/discussdetails",
        query: {
          id: this.every.discussId,
        },
      });
      window.open(url.href, "_blank");
    },
    async spaceScholarInfo(data) {
      const resp = await this.$apis.autherServe.spaceScholarInfo(data);
      this.autherObj = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.update-container {
  margin-bottom: 40px;
  width: 1040px;
  // height: 250px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 12px;
  padding: 30px;
  .headr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .left {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
      }
      .info {
        .name {
          font-size: 22px;
          color: #444444;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            margin-right: 6px;
          }
        }
        .update {
          font-size: 16px;
          color: #999999;
        }
      }
    }
    .right {
      span {
        font-size: 16px;
        color: #00a4ff;
        cursor: pointer;
        width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        display: inline-block;
        text-align: right;
      }
    }
  }
  .title {
    margin-top: 19px;
    font-size: 24px;
    color: #00a4ff;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contents {
    margin-top: 18px;
    font-size: 16px;
    color: #333333;
    word-break: break-all;
    text-align: justify;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .createDate {
    font-size: 14px;
    color: #999999;
    margin-top: 25px;
  }
}
</style>
